import React, { useMemo, useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import { SECTION_MARGIN } from '../../../utils/constants'
// Types
import { SectionTabulatedContent } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Button, Flex, Heading, Image, Text } from '../../../atoms'
import { ContentMd } from '../../commons'
import { StaticImage } from 'gatsby-plugin-image'

const TabulatedContent: React.FC<SectionTabulatedContent> = (props) => {
  const { id, sectionId, headline, tabs, button, whiteText, backgroundColor } = props

  const contentColor = useMemo(() => (whiteText ? 'white' : 'dark'), [])

  const tabsParentRef = useRef<HTMLDivElement | null>(null)
  const titleTabsRefs = useRef(new Map())
  const [activeIndex, setActiveIndex] = useState(0)
  const [indicatorVariants, setIndicatorVariants] = useState({})

  useEffect(() => {
    const listener = () => {
      if (!tabsParentRef || !tabsParentRef.current) {
        return {}
      }
      //Ignore tabsParentRef.current is possibly null warning because
      //we check just above :rolling_eyes:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const variants = tabs.reduce((variants, tab) => {
        const element = titleTabsRefs.current.get(tab.id)
        const tabOffset = element.offsetLeft
        return {
          ...variants,
          [tab.id]: {
            transform: `translate3d(${tabOffset}px, 0,0)`,
            width: element.offsetWidth + 'px',
          },
        }
      }, {})
      setIndicatorVariants(variants)
    }
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [tabs])

  useEffect(() => {
    //Tricks to update variants
    setTimeout(() => window.dispatchEvent(new Event('resize')))
  }, [])

  return (
    <Box as={'section'} id={sectionId || id} backgroundColor={backgroundColor?.hex || 'white'} overflow={'hidden'}>
      <Grid>
        <Box position={'relative'} py={SECTION_MARGIN}>
          <Box display={['none', 'none', 'block']} position={'absolute'} top={'-100px'} left={'-475px'} zIndex={0}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../../../images/ellipse_tabulated.png'}
              alt={''}
              placeholder={'none'}
              width={475}
            />
          </Box>
          <Box display={['none', 'none', 'block']} position={'absolute'} right={'-247px'} bottom={'-124px'} zIndex={0}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../../../images/grid.png'}
              alt={''}
              placeholder={'none'}
              width={247}
            />
          </Box>
          <Box position={'relative'} zIndex={1}>
            <Row>
              <Col xs={12}>
                <Box mb={[8, 9, 10]}>
                  <Heading as={'h2'} type={'heading1'} textAlign={['left', 'center']} color={contentColor}>
                    {headline}
                  </Heading>
                </Box>
                {tabs.length > 0 && (
                  <>
                    {/* TABS */}
                    <Box
                      mb={[8, 9, 10]}
                      mx={['-15px', 'auto']}
                      overflow={'auto'}
                      textAlign={'center'}
                      ref={tabsParentRef}
                    >
                      <Box
                        display={'inline-block'}
                        position='relative'
                        textAlign={'center'}
                        mx={4}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {tabs.map((t, i) => (
                          <Box
                            key={t.id}
                            display={'inline-block'}
                            px={[3, 4, 5]}
                            pt={[1, 2, 2]}
                            pb={22}
                            clickable
                            onClick={() => {
                              setActiveIndex(i)
                              if (tabsParentRef && tabsParentRef.current) {
                                const element = titleTabsRefs.current.get(t.id)
                                tabsParentRef.current.scrollTo({
                                  top: 0,
                                  left: element.offsetLeft - 32,
                                  behavior: 'smooth',
                                })
                              }
                            }}
                            ref={(node) => titleTabsRefs.current.set(t.id, node)}
                          >
                            <Text fontWeight={'bold'} color={contentColor}>
                              {t.tabLabel}
                            </Text>
                          </Box>
                        ))}

                        <Box
                          position={'absolute'}
                          bottom={0}
                          left={0}
                          width={'100%'}
                          height={2}
                          opacity={0.1}
                          backgroundColor={contentColor}
                          borderRadius={'circle'}
                        />

                        <motion.div
                          variants={indicatorVariants}
                          animate={tabs[activeIndex] ? tabs[activeIndex].id : 'none'}
                          transition={{
                            transform: { type: 'tween', duration: 0.5 },
                            width: { type: 'tween', stiffness: 120 },
                          }}
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            height: 2,
                            background: whiteText ? '#FFF' : '#0E0E0F',
                            borderRadius: 2,
                            width: 100,
                            left: 0,
                          }}
                        />
                      </Box>
                    </Box>
                    {/* CONTENT */}
                    <Box>
                      <Row middle={'xs'} center={'xs'}>
                        <Col xs={12} sm={10} md={6}>
                          <Box pr={[0, 0, 0, 8]}>
                            <Heading as={'h3'} type={'heading1'} color={contentColor}>
                              {tabs[activeIndex].headline}
                            </Heading>
                            {!!tabs[activeIndex].content && (
                              <Box mt={6}>
                                <ContentMd content={tabs[activeIndex].content} color={contentColor} />
                              </Box>
                            )}
                            {tabs[activeIndex].buttons.length > 0 && (
                              <Flex mt={6} alignItems={'center'} flexWrap={'wrap'}>
                                {tabs[activeIndex].buttons.map((b, idx) => (
                                  <Button
                                    key={b.id}
                                    {...b}
                                    my={2}
                                    mr={idx + 1 < tabs[activeIndex].buttons.length ? [4, 4, 5] : undefined}
                                  />
                                ))}
                              </Flex>
                            )}
                          </Box>
                        </Col>
                        {tabs[activeIndex].asset && (
                          <Col xs={12} sm={10} md={6}>
                            <Box mt={[6, 6, 0]} pl={[0, 0, 0, 8]}>
                              <Image
                                alt={tabs[activeIndex].asset.alt || tabs[activeIndex].headline}
                                title={tabs[activeIndex].asset.title || tabs[activeIndex].headline}
                                image={tabs[activeIndex].asset.gatsbyImageData}
                              />
                            </Box>
                          </Col>
                        )}
                      </Row>
                    </Box>
                  </>
                )}

                {button && button.length > 0 && (
                  <Flex
                    mt={[6, 8, 10]}
                    width={'100%'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={4}
                  >
                    {button.map((btn) => {
                      return <Button key={btn.id} {...btn} />
                    })}
                  </Flex>
                )}
              </Col>
            </Row>
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}

export default TabulatedContent
